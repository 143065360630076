import React from 'react';
import './style/welcome.css';
import anime from 'animejs';
import { useEffect, useState } from 'react';
import { Button, Fade } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
const timeline = anime.timeline;

interface WelcomeProps {
  onEnter: () => any;
  title: string;
  subtitle: string;
}

export const Welcome = ({ onEnter, title, subtitle }: WelcomeProps) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const { palette } = useTheme();
  useEffect(() => {
    //   Title
    const textWrapper = document.querySelector('.ml1 .letters');
    if (!textWrapper.textContent) return;
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /\S/g,
      "<span class='letter'>$&</span>"
    );
    timeline({ loop: false })
      .add({
        targets: '.ml1 .letter',
        scale: [0.3, 1],
        opacity: [0, 1],
        translateZ: 0,
        easing: 'easeOutExpo',
        duration: 600,
        delay: (el, i) => 70 * (i + 1),
      })
      .add({
        targets: '.ml1 .line',
        scaleX: [0, 1],
        opacity: [0.5, 1],
        easing: 'easeOutExpo',
        duration: 700,
        offset: '-=875',
        delay: (el, i, l) => 80 * (l - i),
      });
    // Description
    const textWrapper2 = document.querySelector('.ml3');
    if (!textWrapper2.textContent) return;
    textWrapper2.innerHTML = textWrapper2.textContent.replace(
      /\S/g,
      "<span class='letter'>$&</span>"
    );
    timeline({ loop: false })
      .add({
        targets: '.ml3 .letter',
        opacity: 0,
        duration: 1300,
      })
      .add({
        targets: '.ml3 .letter',
        opacity: [0, 1],
        easing: 'easeInOutQuad',
        duration: 1000,
        delay: 1300,
        complete: () => setAnimationFinished(true),
      });
  }, []);
  return (
    <div
      css={css`
        z-index: 60;
        position: absolute;
        height: 100vh;
        width: 100vw;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .line {
          background-color: ${palette.primary.main};
        }
      `}
    >
      <h1 className="ml1">
        <span className="text-wrapper">
          <span
            style={{ color: palette.primary.main }}
            className="line line1"
          ></span>
          <span className="letters">{title} </span>
          <span className="line line2"></span>
        </span>
      </h1>
      <h1 className="ml3 mt-8 mb-4">{subtitle}</h1>
      {
        <Fade appear in={animationFinished === true}>
          <WelcomeButton onClick={onEnter}>Comenzar</WelcomeButton>
        </Fade>
      }
    </div>
  );
};

const WelcomeButton = styled.button`
  padding: 1em 2em;
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
`;
