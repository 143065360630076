import React from 'react';
import tw from 'twin.macro';
export function HomePageFeatures() {
  const actionLines = [
    {
      title: 'Excelencia académica',
      description: 'Formación para un desarrollo exitoso',
      objective:
        'Ofrecer una educación integral y pertinente, promotora de valores, con altos estándares de calidad y niveles de logro educativo; equitativa, incluyente, innovadora, con oportunidades de aprendizaje significativo, interdisciplinario y global para toda la vida, al servicio del desarrollo de la sociedad',
    },
    {
      title: 'Profesionalización docente',
      description: 'Superación académica y desarrollo humano',
      objective:
        'Contar con una planta académica altamente calificada para el desempeño de sus funciones, bajo estándares nacionales e internacionales, impulsando su desarrollo personal para mejorar su calidad de vida',
    },
    {
      title: 'Forjando investigadores',
      description: 'Fortalecimiento de la investigación',
      objective:
        'Contribuir al avance del conocimiento con un sistema eficiente de investigación, innovación, desarrollo tecnológico y emprendimiento, que atienda los problemas sociales prioritarios, al servicio de las personas y con un impacto significativo en el desarrollo de propuestas en los sectores académico, científico y productivo',
    },
    {
      title: 'Comunidad y vinculación estratégica',
      description: 'Difusión y extensión universitaria',
      objective:
        'Consolidar la colaboración, el intercambio, la extensión de los servicios, la vinculación del quehacer institucional y la difusión de la cultura, en estrecha relación con los sectores público, social y empresarial, para lograr que la UANL potencie su liderazgo y reconocimiento como una Institución al servicio de la sociedad, por sus alianzas estratégicas y esquemas sustentables',
    },
    {
      title: 'Vida saludable y bienestar del universitario',
      description: '',
      objective:
        'Consolidar programas y políticas institucionales para el cuidado de la salud física y mental de los universitarios y de la comunidad en general a través de educación y acciones para lograr una forma de vida saludable',
    },
    {
      title: 'Gestión con humanismo para el éxito universitario',
      description: '',
      objective:
        'Asegurar la operación eficaz y eficiente de la gestión institucional, bajo un marco regulatorio pertinente en permanente actualización, de conformidad con los principios de la autonomía universitaria, que garantice un crecimiento ordenado con responsabilidad social',
    },
    {
      title: 'Cuerpos colegiados',
      description: 'Órganos de gobierno y consulta',
      objective:
        'Fortalecer la operación de los órganos colegiados y de gobierno para asegurar la retroalimentación efectiva, la consulta propositiva y la toma decisiones estratégicas',
    },
    {
      title: 'Procuración de fondos',
      description: 'Plataforma de oportunidades',
      objective:
        'Generar un esquema para la diversificación de las fuentes alternativas de obtención de recursos que garantice el cumplimiento de los fines de la propia universidad',
    },
  ];
  return (
    <div tw="bg-gray-50 my-8 py-4">
      <h1 tw="mx-auto text-center text-xl mt-4 text-gray-700 font-bold ">
        Programas Institucionales
      </h1>
      <section tw="mb-16 mt-8 container gap-4 grid md:grid-cols-2 px-4 mx-auto">
        {actionLines.map((actionLine, index) => (
          <div tw="flex">
            <h1
              style={{ background: '#b68c1d' }}
              tw="flex-grow h-20 w-20 mr-4 grid text-white text-2xl place-items-center"
            >
              {index + 1}
            </h1>
            <div tw="w-full">
              <p tw="font-medium text-lg">{actionLine.title}</p>
              <p tw="text-gray-600">{actionLine.description}</p>
            </div>
          </div>
        ))}
        <div></div>
        <a
          href="/assets/plan_trabajo.pdf"
          download
          tw="mt-8 rounded-md md:col-span-2 space-x-4 bg-gray-100 h-20 flex justify-center items-center p-4"
        >
          <svg
            fill="#999999"
            tw="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z" />
          </svg>
          <span>Descargar Plan de Trabajo</span>
        </a>
      </section>
    </div>
  );
}
