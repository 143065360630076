import React from 'react';
import tw from 'twin.macro';

export function HomePageCollage() {
  return (
    <div tw=" py-4 mb-16">
      <img loading="lazy" tw="w-full sm:hidden" src="/assets/collage_sm.png" />
      <img
        loading="lazy"
        tw="w-full hidden sm:block lg:hidden"
        src="/assets/collage_md.png"
      />
      <img
        loading="lazy"
        tw="w-full hidden lg:block"
        src="/assets/collage_lg.png"
      />
    </div>
  );
}
