import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  colors,
} from '@material-ui/core';
import {
  MailOutlined,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { Paragraph } from '../Typography/Paragraph';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Title } from '../Typography/Title';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type LoginFormProps = {
  onSubmit: (email: string, password: string) => Promise<any>;
  registerPath?: string;
  onSendRecoveryEmail: (email: string) => Promise<any>;
};

type LoginForm = {
  email: string;
  password: string;
};

export const LoginForm = ({
  onSubmit,
  registerPath,
  onSendRecoveryEmail,
}: LoginFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginForm>();
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState({ hasError: false, message: '' });
  const history = useHistory();
  const gotoSignUp = () => history.push(registerPath);

  const onFormSubmit = async (input: LoginForm) => {
    setFormError({ hasError: false, message: '' });
    try {
      await onSubmit(input.email, input.password);
    } catch (error) {
      setFormError({
        hasError: true,
        message: 'Correo o contraseña incorrectos',
      });
    }
    return;
  };

  const [showEmailRecovery, setShowEmailRecovery] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const handleEmailRecoveryClose = () => setShowEmailRecovery(false);
  const sendRecoveryEmail = async () => {
    if (recoveryEmail === '') return;
    await onSendRecoveryEmail(recoveryEmail);
    setShowEmailRecovery(false);
  };

  return (
    <LoginBaseDiv>
      <form
        css={css`
          display: grid;
          grid-template-rows: repeat(1fr);
          row-gap: 1em;
        `}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Title>Inicia sesión</Title>
        <Controller
          control={control}
          name="email"
          defaultValue={''}
          rules={{
            required: {
              value: true,
              message: 'Por favor introduce un correo',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlined color="primary" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              label="Correo electrónico"
              {...field}
              helperText={
                error ? error?.message : 'Ingresa un correo electrónico'
              }
              error={!!error}
              inputProps={{ 'data-testid': 'login_email' }}
            />
          )}
        />
        <br />
        <Controller
          control={control}
          name="password"
          defaultValue={''}
          rules={{
            required: {
              value: true,
              message: 'Por favor introduce una contraseña',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined color="primary" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      onClick={() => setShowPassword((sp) => !sp)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              label="Contraseña"
              {...field}
              helperText={error ? error?.message : 'Ingresa una contraseña'}
              error={!!error}
              inputProps={{ 'data-testid': 'login_password' }}
            />
          )}
        />

        <div
          css={css`
            justify-self: right;
            width: 100%;
            display: flex;
            flex-direction: flex-row;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Paragraph
            css={css`
              color: ${formError.hasError ? colors.red[400] : '#fff'};
            `}
          >
            {formError.message || 'a'}
          </Paragraph>
          <Button type="button" onClick={() => setShowEmailRecovery(true)}>
            <Paragraph>Olvidé mi contraseña</Paragraph>
          </Button>
        </div>
        <Dialog
          open={showEmailRecovery}
          onClose={setShowEmailRecovery}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Reestablecer contraseña
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Introduce tu dirección de correo electrónico para que podamos
              enviarte un correo de recuperación de contraseña
            </DialogContentText>
            <TextField
              value={recoveryEmail}
              onChange={(e) => setRecoveryEmail(e.target.value)}
              autoFocus
              margin="dense"
              id="name"
              label="Correo"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEmailRecoveryClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={sendRecoveryEmail} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          style={{ opacity: isSubmitting ? 0.5 : 1, color: 'white' }}
          variant="contained"
          color="primary"
          value="Iniciar sesión"
          onClick={handleSubmit(onFormSubmit)}
          data-testid="login_submit"
        >
          INICIAR SESIÓN
        </Button>
        {registerPath && (
          <div>
            <Button onClick={gotoSignUp}>
              <Paragraph>
                No tienes una cuenta ?
                <span className="text-primary"> Registrate</span>
              </Paragraph>
            </Button>
          </div>
        )}
      </form>
    </LoginBaseDiv>
  );
};

const LoginBaseDiv = styled.div``;
