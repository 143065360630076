import { createAudioSlice, AudioManager } from '@oop/data';
import { RootState } from '../store';

export const AUDIO_FUKUROU = 'Fukurou';

const audioSlice = createAudioSlice({
  audios: {
    [AUDIO_FUKUROU]: {
      name: AUDIO_FUKUROU,
      state: { paused: false },
      audio: new AudioManager(new Audio('/assets/Fukurou.mp3')),
    },
  },
});

// export const { pauseAudio, playAudio, synchronizeState } = audioSlice.actions;
// playAudio({ audioName: '' });
export const audioReducer = audioSlice.reducer;
export const audiosState = (state: RootState) => state.audios;
