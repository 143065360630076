import { either, equals, ifElse, isNil } from 'ramda';
import { SceneConfigs } from '../../config/SceneConfig';
import { RFNs } from '../../config/SceneConfigTypes';
import { FileName, LazyLoadRenders, createNavigationSlice } from '@oop/data';
import { LastPageOutlined } from '@material-ui/icons';

const rememberLocation = true;
let lastLocation = rememberLocation
  ? localStorage.getItem('lastLocation') || RFNs['r1']
  : RFNs['r1'];

lastLocation = 'render0001';

export const navigationSlice = createNavigationSlice(
  {
    currentRender: lastLocation,
    loadedRenders: LazyLoadRenders(
      lastLocation,
      [lastLocation],
      SceneConfigs,
      2
    ).map((r) => ({ render: r, fullyLoaded: false })),
    isTransitioning: false,
  },
  RFNs['r1'],
  SceneConfigs,
  RFNs
);

export const {
  goTo,
  setIsTransitioning,
  setRenderLoaded,
} = navigationSlice.actions;
