import React from 'react';
import { Theme } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { PulseSpinner } from './Spinners';
import styled from '@emotion/styled';
import { getColor } from '../../utilities/utilities';

export interface LoadingScreenAProps {}

export const LoadingScreenA: React.FC<LoadingScreenAProps> = () => {
  return (
    <LoadingScreenContainer>
      <PulseSpinner />
    </LoadingScreenContainer>
  );
};

const LoadingScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;
