import {
  InputAdornment,
  TextField,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Email, TextFields, AccountCircle } from '@material-ui/icons';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CreateProposalDto } from '@oop/plan-virtual-data';
import { css } from '@emotion/react';
import { ModalContext, Title, getMq, PdfModal } from '@oop/ui';
import firebase from 'firebase/app';
import { sleep } from '@oop/data';

interface CreateProposalProps {
  onSubmit: (d: CreateProposalDto) => Promise<any>;
  onError: () => void;
}
export interface ProposalForm {
  proposal: string;
  name: string;
  email: string;
}

export const CreateProposal = ({ onSubmit, onError }: CreateProposalProps) => {
  const defaultValues = { proposal: '', email: '', name: '', plan: '' };
  const { closeModal, openModalWith } = useContext(ModalContext);
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<ProposalForm>({
    defaultValues,
    reValidateMode: 'onSubmit',
  });

  const submit = async ({ email, name, proposal }: ProposalForm) => {
    try {
      await onSubmit({
        proposal,
        email: email === '' ? null : email,
        name: name === '' ? null : name,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        timesShown: 0,
        plan: null,
        active: true,
      });
      reset(defaultValues);
    } catch (error) {
      onError();
    }
  };

  return (
    <div>
      <form
        css={css`
          display: grid;
          padding: 1em;
          grid-template-columns: auto;
          grid-gap: 1em;
        `}
        onSubmit={handleSubmit(submit)}
      >
        <Title>Envía tu mensaje de Felicitación al Dr. Santos Guzman</Title>
        <Controller
          control={control}
          name="proposal"
          rules={{
            required: {
              value: true,
              message: 'Por favor llena el campo',
            },
            maxLength: {
              value: 500,
              message: 'No se permiten más de 500 caractéres',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              type="text"
              rows={10}
              maxRows={10}
              variant="outlined"
              multiline
              label="Mensaje"
              {...field}
              helperText={error ? error?.message : 'Ingresa tu mensaje'}
              error={!!error}
              inputProps={{ 'data-testid': 'proposalform_proposal' }}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          rules={{
            required: {
              value: true,
              message: 'Por favor llena el campo',
            },
            maxLength: {
              value: 100,
              message: 'No se permiten más de 100 caractéres',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              type="text"
              variant="outlined"
              label="Correo"
              {...field}
              helperText={error ? error?.message : 'Ingresa tu correo'}
              error={!!error}
              inputProps={{ 'data-testid': 'proposalform_email' }}
            />
          )}
        />
        <Controller
          control={control}
          name="name"
          rules={{
            required: {
              value: true,
              message: 'Por favor llena el campo',
            },
            maxLength: {
              value: 70,
              message: 'No se permiten más de 70 caractéres',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              type="text"
              variant="outlined"
              label="Nombre"
              {...field}
              helperText={error ? error?.message : 'Ingresa tu nombre'}
              error={!!error}
              inputProps={{ 'data-testid': 'proposalform_name' }}
            />
          )}
        />

        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            onClick={handleSubmit(submit)}
            data-testid="submit_btn"
          >
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
};
