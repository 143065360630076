import {
  FileNames,
  Totem as TotemT,
  SceneConfig as SceneConfigT,
} from '@oop/data';

export type MuralInformation = {
  proposalNumber: number;
  videoLink: string;
  pdfName: string;
};

export type SceneConfig = SceneConfigT<MuralInformation>;
export type Totem = TotemT<MuralInformation>;

export const RFNs: FileNames = {
  // RenderFileNames
  r1: 'render0001',
  r2: 'render0002',
  r3: 'render0003',
  r4: 'render0004',
  r5: 'render0005',
  r6: 'render0006',
  r7: 'render0007',
  r8: 'render0008',
  r9: 'render0009',
  r10: 'render0010',
  r11: 'render0011',
  r12: 'render0012',
  r13: 'render0013',
  r14: 'render0014',
  r15: 'render0015',
  r16: 'render0016',
  r17: 'render0017',
  r18: 'render0018',
};
