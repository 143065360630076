import React from 'react';
import tw from 'twin.macro';
import { useHistory } from 'react-router';

export function QrPage() {
  const history = useHistory();
  const cards = [
    {
      imgageSrc: '/assets/propuestas.jpg',
      text: 'Propuestas del candidato',
      to: 'https://forms.gle/irheXPbHEzzhtCrh7',
    },
    {
      imgageSrc: '/assets/satisfaccion.jpg',
      text: 'Encuesta de satisfaccion',
      to: 'https://forms.gle/ujgqK1V8JwYq7od27',
    },
    {
      imgageSrc: '/assets/plan_trabajo.jpg',
      text: 'Plan de trabajo',
      to: '/assets/plan_trabajo.pdf',
    },
  ];

  return (
    <div tw="bg-gray-50 flex flex-col justify-center items-center min-h-screen p-4 md:p-16">
      <div tw="w-full  max-w-xl">
        <button
          onClick={() => history.push('/')}
          tw="w-8 mb-2 h-8 bg-gray-200 rounded-md grid flex items-center justify-center"
        >
          <svg
            tw="h-4 w-4 absolute"
            fill="#777777"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
        </button>
      </div>
      <div tw="p-2 md:p-8 max-w-xl bg-white rounded-lg shadow-xl">
        <img
          src="/assets/flama.png"
          style={{
            maskImage:
              'linear-gradient(to bottom, black 80%, transparent 100% )',
          }}
        />
        <div tw="-mb-8 text-center flex items-center flex-col">
          <p tw="text-lg font-bold">Dr. med. Santos Guzman Lopez</p>
          <p>Candidatura a Rector de la UANL 2024-2027</p>
        </div>
        <div tw="mt-16 flex flex-col space-y-3">
          {cards.map((card, cardIndex) => {
            const extras = {} as any;
            if (cardIndex === 2) {
              extras.download = true;
            } else {
              extras.target = '_blank';
            }
            return (
              <a
                href={card.to}
                {...extras}
                tw="flex bg-gray-100 p-4 rounded-md justify-between items-center"
              >
                <img tw="h-20" src={card.imgageSrc} />
                <p>{card.text}</p>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
