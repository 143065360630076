import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NavbarLinks } from '@oop/ui';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, useHistory } from 'react-router-dom';

interface MobileMenuA {
  links: NavbarLinks;
  signInRoute: string;
  signUpRoute: string;
}

export const MobileMenuA = ({
  links,
  signInRoute,
  signUpRoute,
}: MobileMenuA) => {
  const burger = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const toggleBtn = () => setIsOpen((o) => !o);
  return (
    <>
      {ReactDOM.createPortal(
        <StyledNav isOpen={isOpen}>
          <ul>
            {Object.keys(links).map((title) => (
              <li key={`mobile_${title}`}>
                <Link to={links[title]}>{title}</Link>
              </li>
            ))}
            <li>
              <Link to={signInRoute}>Acceder</Link>
            </li>
            <li>
              <Link to={signUpRoute}>Registrarse</Link>
            </li>
          </ul>
        </StyledNav>,
        document.getElementById('root')
      )}
      <StyledBurgerButton isOpen={isOpen} onClick={toggleBtn}>
        <span className="burger"></span>
        <span className="burger-text">Menu</span>
      </StyledBurgerButton>
    </>
  );
};

interface StyledBurgerButtonProps {
  isOpen: boolean;
}
const StyledBurgerButton = styled.button<StyledBurgerButtonProps>`
  position: absolute;
  top: 15px;
  padding-top: 20px;
  right: 15px;
  z-index: 1000;
  background: none;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .burger {
    position: relative;
    display: block;
    width: 28px;
    height: 4px;
    margin: 0 auto;
    background: ${({ theme }) => theme.palette.primary.main};
    transform: ${({ isOpen }) =>
      isOpen ? 'skew(5deg) translateY(-8px) rotate(-45deg)' : 'skew(5deg)'};
    transition: all 0.275s;
  }

  .burger:after,
  .burger:before {
    content: '';
    display: block;
    height: 100%;
    background: ${({ theme }) => theme.palette.primary.main};
    transition: all 0.375s;
  }

  .burger:after {
    transform: ${({ isOpen }) =>
      isOpen
        ? 'translateY(-12px) translateX(10px) skew(-20deg)'
        : 'translateY(-12px) translateX(-2px) skew(-20deg)'};
    opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
  }

  .burger:before {
    transform: ${({ isOpen }) =>
      isOpen
        ? 'translateY(0px) skew(-10deg) rotate(75deg)'
        : 'translateY(-16px) skew(-10deg)'};
  }

  .burger-text {
    display: block;
    font-size: 0.675rem;
    letter-spacing: 0.05em;
    margin-top: 0.5em;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const isOpen = (then: string, elses: string) => ({
  isOpen,
}: {
  isOpen: boolean;
}) => (isOpen ? then : elses);

const StyledNav = styled.nav<StyledBurgerButtonProps>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: #fff;
  opacity: ${isOpen('1', '0')};
  z-index: ${({ isOpen }) => (isOpen ? 100 : -1)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: all 0.375s;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;
    background: ${({ theme }) => theme.palette.primary.main};
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(${isOpen('0', '-120%')});
    transition: all 0.175s linear;
  }

  ul {
    display: inline-flex;
    flex-direction: column;
    height: 93%;
    align-items: flex-end;
    justify-content: center;
    transform: translateX(-18%) skew(-16deg);
  }

  li {
    display: block;
    margin: 0.5rem 0;
    text-align: right;
    transform: skew(16deg);
  }

  a {
    opacity: ${isOpen('1', '0')};
    transform: translateY(${isOpen('0', '-10px')});
  }

  ${[1, 2, 3, 4, 5]
    .map(
      (n) => `li:nth-child(${n}) a {
	transition: all 275ms ${175 + 50 * n}ms;
            }`
    )
    .join(' ')}

  ul,li {
    list-style: none;
    padding: 0;
  }

  a {
    display: block;
    padding: 12px 0;
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 1.4em;
    text-decoration: none;
    font-weight: bold;
  }
  a:visited {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
