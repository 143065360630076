import styled from '@emotion/styled';
import React from 'react';

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  ${({ theme }) => theme.breakpoints.up('xs')} {
    font-size: 0.8em;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 1em;
  }
`;
