import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isTransitioning } from '@oop/data';

type ASkyProps = {
  color?: string;
  src?: string;
  rotation?: string;
  id?: string;
  isTransitioning?: boolean;
};

export const AASky = ({
  src,
  rotation,
  color,
  isTransitioning = false,
}: ASkyProps) => {
  const from = isTransitioning ? 'white' : 'black';
  const to = isTransitioning ? 'black' : 'white';
  return (
    // @ts-ignore
    <a-sky
      id="sky"
      src={src}
      // src="#render0001"
      rotation={rotation}
      animation={`property: components.material.material.color; type: color; to: ${to} ; from: ${from}; loop: false; dur: 300`}
      // animation__fade="property: components.material.material.color; type: color; from: #FFF; to: #000; dur: 300; startEvents: fade"
      // animation__fadeback="property: components.material.material.color; type: color; from: #000; to: #FFF; dur: 300; startEvents: animationcomplete__fade"
    >
      {/* @ts-ignore */}
    </a-sky>
  );
};
