import { ArrowDownward } from '@material-ui/icons';
import { MuralesInformation, Murales } from './StandsInformation';
import {
  ArrowPosition,
  ArrowRotation,
  SkyRotationNormalization,
} from '@oop/data';
import { RFNs, SceneConfig } from './SceneConfigTypes';

const generateRotation = (phi: number) => {
  return `0 ${(phi - Math.PI) * 57.3} 0`;
};

const T = {
  fromEast: {
    position: '-1.3 1.6 1.5',
    rotation: '0 135 0',
  },
  fromWest: {
    position: '1.3 1.6 -1.5',
    rotation: '0 -45 0',
  },
};

export const SceneConfigs: { [key: string]: SceneConfig } = {
  [RFNs.r1]: {
    cielo: `${RFNs.r1}.jpg`,
    navegadores: [
      {
        to: RFNs.r3,
        rotation: ArrowRotation.Up,
        position: ArrowPosition.Up(5),
        type: 'arrow',
      },
      {
        to: RFNs.r2,
        rotation: ArrowRotation.Custom(-60),
        position: ArrowPosition.Custom(5, -70),
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r2]: {
    cielo: `${RFNs.r2}.jpg`,
    rotacionCielo: '0 -45 0',
    navegadores: [
      {
        to: RFNs.r1,
        rotation: ArrowRotation.Custom(135),
        position: ArrowPosition.Custom(5, 135),
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r3]: {
    cielo: `${RFNs.r3}.jpg`,
    navegadores: [
      {
        to: RFNs.r4,
        rotation: ArrowRotation.Left,
        position: ArrowPosition.Left(5),
        type: 'hotspot',
      },
      {
        to: RFNs.r1,
        rotation: ArrowRotation.Down,
        position: ArrowPosition.Down(5),
        type: 'arrow',
      },
      {
        to: RFNs.r5,
        rotation: ArrowRotation.Up,
        position: ArrowPosition.Up(5),
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r4]: {
    cielo: `${RFNs.r4}.jpg`,
    rotacionCielo: SkyRotationNormalization.eastToNorth,
    navegadores: [
      {
        to: RFNs.r3,
        rotation: ArrowRotation.Right,
        position: ArrowPosition.Right(5),
        type: 'arrow',
      },
    ],
    totems: [
      {
        content: MuralesInformation[Murales.M1],
        ...T.fromEast,
      },
    ],
  },
  [RFNs.r5]: {
    cielo: `${RFNs.r5}.jpg`,
    navegadores: [
      {
        to: RFNs.r6,
        rotation: ArrowRotation.Right,
        position: ArrowPosition.Right(5),
        type: 'hotspot',
      },
      {
        to: RFNs.r3,
        rotation: ArrowRotation.Down,
        position: ArrowPosition.Down(5),
        type: 'arrow',
      },
      {
        to: RFNs.r7,
        rotation: ArrowRotation.Up,
        position: ArrowPosition.Up(5),
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r6]: {
    cielo: `${RFNs.r6}.jpg`,
    rotacionCielo: SkyRotationNormalization.westToNorth,
    navegadores: [
      {
        to: RFNs.r5,
        rotation: ArrowRotation.Left,
        position: ArrowPosition.Left(5),
        type: 'arrow',
      },
    ],
    totems: [
      {
        content: MuralesInformation[Murales.M2],
        ...T.fromWest,
      },
    ],
  },
  [RFNs.r7]: {
    cielo: `${RFNs.r7}.jpg`,
    navegadores: [
      {
        to: RFNs.r8,
        rotation: ArrowRotation.Left,
        position: ArrowPosition.Left(5),
        type: 'hotspot',
      },
      {
        to: RFNs.r9,
        rotation: ArrowRotation.Up,
        position: ArrowPosition.Up(5),
        type: 'arrow',
      },
      {
        to: RFNs.r5,
        rotation: ArrowRotation.Down,
        position: ArrowPosition.Down(5),
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r8]: {
    cielo: `${RFNs.r8}.jpg`,
    rotacionCielo: SkyRotationNormalization.eastToNorth,
    navegadores: [
      {
        to: RFNs.r7,
        rotation: ArrowRotation.Right,
        position: ArrowPosition.Right(5),
        type: 'arrow',
      },
    ],
    totems: [
      {
        content: MuralesInformation[Murales.M3],
        ...T.fromEast,
      },
    ],
  },
  [RFNs.r9]: {
    cielo: `${RFNs.r9}.jpg`,
    navegadores: [
      {
        to: RFNs.r11,
        rotation: ArrowRotation.Up,
        position: ArrowPosition.Up(5),
        type: 'arrow',
      },
      {
        to: RFNs.r10,
        rotation: ArrowRotation.Right,
        position: ArrowPosition.Right(5),
        type: 'hotspot',
      },
      {
        to: RFNs.r7,
        rotation: ArrowRotation.Down,
        position: ArrowPosition.Down(5),
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r10]: {
    cielo: `${RFNs.r10}.jpg`,
    rotacionCielo: SkyRotationNormalization.westToNorth,
    navegadores: [
      {
        to: RFNs.r9,
        rotation: ArrowRotation.Left,
        position: ArrowPosition.Left(5),
        type: 'arrow',
      },
    ],
    totems: [
      {
        content: MuralesInformation[Murales.M4],
        ...T.fromWest,
      },
    ],
  },
  [RFNs.r11]: {
    cielo: `${RFNs.r11}.jpg`,
    navegadores: [
      {
        to: RFNs.r9,
        rotation: ArrowRotation.Down,
        position: ArrowPosition.Down(5),
        type: 'arrow',
      },
      {
        to: RFNs.r13,
        rotation: ArrowRotation.Up,
        position: ArrowPosition.Up(5),
        type: 'arrow',
      },
      {
        to: RFNs.r12,
        rotation: ArrowRotation.Left,
        position: ArrowPosition.Left(5),
        type: 'hotspot',
      },
    ],
    totems: [],
  },
  [RFNs.r12]: {
    cielo: `${RFNs.r12}.jpg`,
    rotacionCielo: SkyRotationNormalization.eastToNorth,
    navegadores: [
      {
        to: RFNs.r11,
        rotation: ArrowRotation.Right,
        position: ArrowPosition.Right(5),
        type: 'arrow',
      },
    ],
    totems: [
      {
        content: MuralesInformation[Murales.M5],
        ...T.fromEast,
      },
    ],
  },
  [RFNs.r13]: {
    cielo: `${RFNs.r13}.jpg`,
    navegadores: [
      {
        to: RFNs.r11,
        rotation: ArrowRotation.Down,
        position: ArrowPosition.Down(5),
        type: 'arrow',
      },
      {
        to: RFNs.r15,
        rotation: ArrowRotation.Up,
        position: ArrowPosition.Up(5),
        type: 'arrow',
      },
      {
        to: RFNs.r14,
        rotation: ArrowRotation.Right,
        position: ArrowPosition.Right(5),
        type: 'hotspot',
      },
    ],
    totems: [],
  },
  [RFNs.r14]: {
    cielo: `${RFNs.r14}.jpg`,
    rotacionCielo: SkyRotationNormalization.westToNorth,
    navegadores: [
      {
        to: RFNs.r13,
        rotation: ArrowRotation.Left,
        position: ArrowPosition.Left(5),
        type: 'arrow',
      },
    ],
    totems: [
      {
        content: MuralesInformation[Murales.M6],
        ...{
          rotation: T.fromWest.rotation,
          position: '1.3 1.6 -1.3',
        },
      },
    ],
  },
  [RFNs.r15]: {
    cielo: `${RFNs.r15}.jpg`,
    navegadores: [
      {
        to: RFNs.r13,
        rotation: ArrowRotation.Down,
        position: ArrowPosition.Down(5),
        type: 'arrow',
      },
      {
        to: RFNs.r16,
        rotation: ArrowRotation.Left,
        position: ArrowPosition.Left(5),
        type: 'hotspot',
      },
      {
        to: RFNs.r17,
        rotation: ArrowRotation.Up,
        position: ArrowPosition.Up(5),
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r16]: {
    cielo: `${RFNs.r16}.jpg`,
    rotacionCielo: SkyRotationNormalization.eastToNorth,
    navegadores: [
      {
        to: RFNs.r15,
        rotation: ArrowRotation.Right,
        position: ArrowPosition.Right(5),
        type: 'arrow',
      },
    ],
    totems: [
      {
        content: MuralesInformation[Murales.M7],
        ...T.fromEast,
      },
    ],
  },
  [RFNs.r17]: {
    cielo: `${RFNs.r17}.jpg`,
    navegadores: [
      {
        to: RFNs.r18,
        rotation: ArrowRotation.Right,
        position: ArrowPosition.Right(5),
        type: 'hotspot',
      },
      {
        to: RFNs.r15,
        rotation: ArrowRotation.Down,
        position: ArrowPosition.Down(5),
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r18]: {
    cielo: `${RFNs.r18}.jpg`,
    rotacionCielo: SkyRotationNormalization.westToNorth,
    navegadores: [
      {
        to: RFNs.r17,
        rotation: ArrowRotation.Left,
        position: ArrowPosition.Left(5),
        type: 'arrow',
      },
    ],
    totems: [],
  },
};
