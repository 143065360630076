import styled from '@emotion/styled';
import { range } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { getColor } from '../../utilities/utilities';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress, IconButton } from '@material-ui/core';
import {
  CellWifiRounded,
  Close,
  Fullscreen,
  ZoomIn,
  ZoomOut,
} from '@material-ui/icons';
import { css } from '@emotion/react';
import { isMobile } from '../../utilities/utilities';

interface PdfModalProps {
  pdf: string;
  closeModal: () => any;
}
export const PdfModal: React.FC<PdfModalProps> = ({ pdf, closeModal }) => {
  const [numPages, setNumPages] = useState(0);
  const pages = range(1, numPages + 1);
  const onDocumentLoad = ({ numPages, ...rest }) => {
    setNumPages(numPages);
  };

  const docContainerRef = useRef<HTMLDivElement>();
  const [pageW, setPageW] = useState(0);

  useEffect(() => {
    const el = docContainerRef.current;
    if (!el || pageW !== 0) return;
    setPageW(el.scrollWidth);
  }, [docContainerRef]);

  const _ = () => 1;

  const [alreadySetFns, setAlreadySetFns] = useState(false);
  const [{ resetTransform, zoomIn, zoomOut }, setFns] = useState({
    zoomIn: _,
    zoomOut: _,
    resetTransform: _,
  });
  return (
    <>
      <DocumentContainer ref={docContainerRef}>
        <TransformWrapper
          pan={{ disabled: isMobile() }}
          wheel={{ disabled: true }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => {
            if (!alreadySetFns) {
              setAlreadySetFns(true);
              setFns({
                resetTransform,
                zoomIn,
                zoomOut,
              });
            }
            return (
              <TransformComponent>
                <Document
                  loading={''}
                  file={pdf}
                  onLoadSuccess={onDocumentLoad}
                >
                  {pages.map((pn) => (
                    <Page
                      loading={
                        <Skeleton
                          width={pageW}
                          height={600}
                          animation="pulse"
                        />
                      }
                      width={pageW}
                      pageNumber={pn}
                    />
                  ))}
                </Document>
              </TransformComponent>
            );
          }}
        </TransformWrapper>
      </DocumentContainer>
      <IconsContainer>
        <div className="left">
          {/* <IconButton onClick={closeModal} color="primary" size="small">
            <Close />
          </IconButton> */}
        </div>
        <div className="right">
          <IconButton onClick={zoomOut} color="secondary" size="small">
            <ZoomOut />
          </IconButton>
          <IconButton onClick={zoomIn} color="primary" size="small">
            <ZoomIn />
          </IconButton>
          <IconButton onClick={resetTransform} color="primary" size="small">
            <Fullscreen />
          </IconButton>
        </div>
      </IconsContainer>
    </>
  );
};

const DocumentContainer = styled.div`
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  // .react-transform-component,
  // .react-transform-element,
  // .react-pdf__Document,
  // .react_pdf__Page {
  //   width: 100%;
  // }
`;

const IconsContainer = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  top: 1em;
  padding: 0 1em;
  display: flex;
  flex-direction: row;
  z-index: 60;
  width: 100%;
  display: flex;
  justify-content: space-between;

  i {
    margin: 0 0.5em;
    font-size: 1.1em;
    cursor: pointer;
  }
`;
