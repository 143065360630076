import { useEffect, useState } from 'react';
import {
  differenceInMinutes,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
} from 'date-fns';

export const useCountdown = (until: Date) => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const intId = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(intId);
  }, []);
  const add0IfNeeded = (n: number) =>
    String(n).length === 1 ? `0${n}` : String(n);
  return {
    days: add0IfNeeded(differenceInDays(until, now) % 7),
    hours: add0IfNeeded(differenceInHours(until, now) % 24),
    minutes: add0IfNeeded(differenceInMinutes(until, now) % 60),
    seconds: add0IfNeeded(differenceInSeconds(until, now) % 60),
  };
};
