import { Paper } from '@material-ui/core';
import { css } from '@emotion/react';
import { CreateProposal } from '@plan-virtual/components/CreateProposal/CreateProposal';
import { createProposal } from '@plan-virtual/services/proposals/proposals.service';
import { sleep } from '@oop/data';
import { InformativeModal, ModalContext } from '@oop/ui';
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';
export function FormPage() {
  const { closeModal, openModalWith } = useContext(ModalContext);
  return (
    <Container>
      <img tw="" alt="Logo" src="/assets/Logo-Excelencia-Blanco.png" />
      <Paper
        css={css`
          width: 90%;

          @media (min-width: 700px) {
            width: 50%;
          }

          @media (min-width: 1200px) {
            width: 30%;
          }
        `}
      >
        <CreateProposal
          onSubmit={async (p) => {
            await createProposal(p);
            closeModal();
            await sleep(200);
            openModalWith(
              <InformativeModal type="success" message="Propuesta enviada" />
            );
          }}
          onError={async () => {
            closeModal();
            await sleep(200);
            openModalWith(
              <InformativeModal
                type="success"
                message="Error al enviar propuesta"
              />
            );
          }}
        />
      </Paper>
    </Container>
  );
}

const Container = styled.div`
  background: #012d74;
  min-height: 100vh;
  padding: 1em 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 2.5em;
    margin-bottom: 1em;
  }

  @media (min-width: 600px) {
    img {
      height: 5em;
    }
  }
`;
