import { audioReducer } from '@plan-virtual/store/Audio/slice';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { navigationSlice } from './Navigation/slice';

const reducers = combineReducers({
  navigation: navigationSlice.reducer,
  audios: audioReducer,
});

export const store = configureStore({
  reducer: reducers,
});

export type RootState = ReturnType<typeof reducers>;
