import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import { Paragraph } from '../Typography/Paragraph';
import { Container } from '../Containers/Container';
import { Button } from '@material-ui/core';
import { flattenSlashbar } from 'libs/ui/src/utilities/utilities';

export type NavbarLinks = { [key: string]: string };
export interface NavbarProps {
  links: NavbarLinks;
  signUpPath: string;
  signInPath: string;
  title: string;
  logo?: ReactNode;
  burgerMenu: ReactNode;
}

export function NavbarA({
  links,
  title,
  logo,
  burgerMenu,
  signUpPath,
  signInPath,
}: NavbarProps) {
  const linkEls = Object.keys(links).map((title) => (
    <Link className="link" role="link" key={title} to={links[title]}>
      <Paragraph>{title}</Paragraph>
    </Link>
  ));
  const isMobile = window.innerWidth < 768;

  const history = useHistory();
  const { url } = useRouteMatch();
  const gotoSignUp = () => history.push(flattenSlashbar(`${url}${signUpPath}`));
  const gotoSignIn = () => history.push(flattenSlashbar(`${url}${signInPath}`));

  return (
    <Container
      css={css`
        border-bottom: 0.1px solid #ececec;
      `}
      style={{ position: 'relative' }}
    >
      <StyledNavbar>
        <div className="logo_title flex left">
          {logo}
          <p
            css={css`
              font-size: 1.4em;
              margin-left: 0.5em;
              padding: 0;
            `}
          >
            {title}
          </p>
        </div>
        {isMobile ? (
          burgerMenu
        ) : (
          <div
            css={css`
              width: 100%;
            `}
            className="flex"
          >
            {linkEls}
            <div
              css={css`
                margin-left: auto;
              `}
            >
              <Button
                onClick={gotoSignIn}
                css={css`
                  margin-right: 1em;
                `}
                variant="outlined"
                color="primary"
              >
                Iniciar sesión
              </Button>
              <Button onClick={gotoSignUp}>Crear cuenta</Button>
            </div>
          </div>
        )}
      </StyledNavbar>
    </Container>
  );
}

const StyledNavbar = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  height: 4em; 

  .logo_title {
    font-size: 1em;
    h1{
      margin-left: 0.4em;
    }
  }

  .left {
    margin-right: 2em;
    margin-bottom: 0.2em;
  }

  .link {
    position: relative;
    color: ${({ theme }) => theme.palette.primary.main}
    border-bottom: 2px solid transparent;
    text-decoration: none;
    margin: 0 0.5em;

    display: flex;
    align-items: center;
  }

  .link::after{
    content: '';
    position: absolute;
    bottom: -4px;
    border-radius: 1em;
    width: 100%;
    transition: all 200ms ease-in-out;
    transform: scaleX(0);
    opacity: 0;
    height: 2.7px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }

  .link:hover:after {
    transform: scaleX(1);
    opacity: 1;
  }

  .link:visited {
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  @media (min-width: 768px) {
  }

  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flexRight {
    justify-content: flex-end;
  }
`;
