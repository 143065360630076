import { MuralInformation } from './SceneConfigTypes';

export enum Murales {
  'M1',
  'M2',
  'M3',
  'M4',
  'M5',
  'M6',
  'M7',
  'M8',
  'M9',
  'M10',
  'M11',
  'M12',
  'M13',
  'M14',
  'M15',
}

export const MuralesInformation: { [key: string]: MuralInformation } = {
  [Murales.M1]: {
    proposalNumber: 1,
    pdfName: 'PlanEstrategico1.pdf',
    videoLink: 'https://youtu.be/Pc_3N7e1IF8',
  },

  [Murales.M2]: {
    proposalNumber: 2,
    pdfName: 'PlanEstrategico2.pdf',
    videoLink: 'https://youtu.be/RxhMjcpczIg',
  },
  [Murales.M3]: {
    proposalNumber: 3,
    pdfName: 'PlanEstrategico3.pdf',
    videoLink: 'https://youtu.be/D3clAdJWfLA',
  },
  [Murales.M4]: {
    proposalNumber: 4,
    pdfName: 'PlanEstrategico4.pdf',
    videoLink: 'https://youtu.be/RMGn8QV7v0g',
  },
  [Murales.M5]: {
    proposalNumber: 5,
    pdfName: 'PlanEstrategico5.pdf',
    videoLink: 'https://youtu.be/-hjGvpTUo7U',
  },
  [Murales.M6]: {
    proposalNumber: 6,
    pdfName: 'PlanEstrategico6.pdf',
    videoLink: 'https://youtu.be/8QqrFcsW_MQ',
  },
  [Murales.M7]: {
    proposalNumber: 7,
    pdfName: 'PlanEstrategico7.pdf',
    videoLink: 'https://youtu.be/SRYVoz0NlF8',
  },
};
