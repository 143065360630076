import { sleep } from '@oop/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export class AudioManager {
  htmlAudio: HTMLAudioElement;
  maxVol = 0.1;
  constructor(audio: HTMLAudioElement) {
    this.htmlAudio = audio;
    this.htmlAudio.volume = this.maxVol;
    this.htmlAudio.loop = true;
  }
  pause = async () => {
    for (let i = this.maxVol * 100; i >= 0; i--) {
      await sleep(100);
      this.htmlAudio.volume = this.maxVol * (i / 10);
    }
    this.htmlAudio.pause();
  };
  play = async () => {
    this.htmlAudio.play();
    for (let i = 0; i < this.maxVol * 100; i++) {
      await sleep(100);
      this.htmlAudio.volume = this.maxVol * (i / 10);
    }
  };
}

interface createAudioSliceArgs {
  audios: Record<string, Audio>;
}
interface Audio {
  name: string;
  audio: AudioManager;
  state?: {
    paused: boolean;
  };
}
interface audioSliceState {
  audios: createAudioSliceArgs['audios'];
}
export const createAudioSlice = ({ audios }: createAudioSliceArgs) => {
  const pauseAudio = createAsyncThunk<
    string,
    { audioName: string },
    { state: audioSliceState }
  >('pauseAudio', async ({ audioName }, { getState }) => {
    const { audios } = getState();
    await audios[audioName].audio.pause();
    return audioName;
  });
  const slice = createSlice({
    name: 'audio',
    initialState: {
      audios,
    } as audioSliceState,
    reducers: {
      synchronizeState: (state) => {
        for (const audioName in state.audios) {
          const currentAudio = state.audios[audioName];
          currentAudio.state.paused = currentAudio.audio.htmlAudio.paused;
        }
      },
      //   pauseAudio: (
      //     state,
      //     {
      //       payload: { audioName },
      //     }: PayloadAction<{ audioName: keyof typeof audios }>
      //   ) => {
      //     const audioToPause = state.audios[audioName];
      //     audioToPause.audio.pause();
      //     audioToPause.state.paused = audioToPause.audio.htmlAudio.paused;
      //   },
      playAudio: (
        state,
        {
          payload: { audioName },
        }: PayloadAction<{ audioName: keyof typeof audios }>
      ) => {
        const audioToPlay = state.audios[audioName];
        audioToPlay.audio.play();
        audioToPlay.state.paused = audioToPlay.audio.htmlAudio.paused;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(pauseAudio.fulfilled, (state, { payload: audioName }) => {
        const audioToPause = state.audios[audioName];
        audioToPause.audio.pause();
        audioToPause.state.paused = audioToPause.audio.htmlAudio.paused;
      });
    },
  });
  return slice;
};
