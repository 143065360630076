import React, { useContext } from 'react';
import { AText, AEntity, ModalContext } from '@oop/ui';
import { isEmpty } from 'ramda';

type IconConfig = {
  iconID: string;
  title: string;
  handleClick: () => void;
};

type TotemProps<T> = {
  position: string;
  rotation: string;
  content: T;
  color: string;
  iconsConfig: IconConfig[];
};

export const Totem4Rows = <T,>({
  position,
  rotation,
  iconsConfig,
  color,
}: TotemProps<T>) => {
  const { openModalWith } = useContext(ModalContext);

  const missingIcons = iconsConfig.reduce(
    (acc, curr) => (curr === undefined ? acc + 1 : acc),
    0
  );
  console.log(missingIcons);

  const YScale = 0.1288;
  const XScale = 0.7;
  const iconPosition = (y: string) => `0 ${y} 0.7`;
  const firstIconY = 0.3;
  const gapBetweenIconYs = 0.215;
  const iconScale = `${XScale} ${YScale} ${XScale}`;

  let currentIcon = -1;
  const IconEntities = iconsConfig.map((config, index) => {
    if (config) currentIcon += 1;
    return config ? (
      <>
        <AText
          color="#222222"
          align="center"
          text={config.title}
          scale="0.8 0.15 0"
          position={iconPosition(
            String(
              firstIconY +
                0.1 -
                currentIcon * gapBetweenIconYs -
                missingIcons * 0.08
            )
          )}
        />
        <AEntity
          handleClick={config.handleClick}
          key={config.iconID + String(Math.random())}
          src={config.iconID}
          position={iconPosition(
            String(
              firstIconY - currentIcon * gapBetweenIconYs - missingIcons * 0.08
            )
          )}
          scale={iconScale}
          isIcon={true}
        />
      </>
    ) : null;
  });

  return (
    <AEntity
      rotation={rotation}
      id="main_totem"
      color="white"
      geometry="box"
      scale="0.368 2 0.1"
      position={position}
    >
      <AText
        color="#222222"
        align="center"
        text="Acciones"
        scale="0.95 0.2 0"
        position={`0 ${0.45 - 0.08 * missingIcons} 1`}
      />
      <AEntity
        id="main_totem_decoration"
        color={color}
        geometry="box"
        scale="0.1 1 0.1"
        position="-0.52 0 0.5"
      />
      {IconEntities}
    </AEntity>
  );
};
