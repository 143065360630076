import React, { ReactNode } from 'react';

type ASceneProps = {
  children: ReactNode[] | ReactNode;
  embedded?: boolean;
  loadingScreen?: {
    dotsColor: string;
    backgroundColor: string;
  };
};

export const AScene = ({
  children,
  embedded = false,
  loadingScreen,
}: ASceneProps) => {
  // eslint-disable-next-line
  let extraProps = {};
  if (loadingScreen) {
    extraProps[
      'loading-screen'
    ] = `dotsColor: ${loadingScreen.dotsColor}; backgroundColor: ${loadingScreen.backgroundColor};`;
  }
  return (
    // eslint-disable-next-line
    // @ts-ignore
    <a-scene
      embedded={embedded}
      {...extraProps}
      width="400px"
      height="400px"
      cursor="fuseTimeout: 0; rayOrigin: mouse;"
      inspector="https://cdn.jsdelivr.net/gh/aframevr/aframe-inspector@master/dist/aframe-inspector.min.js"
    >
      {children}
      {/* @ts-ignore */}
    </a-scene>
  );
};
