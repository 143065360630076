import React, { ReactNode, Reducer, useReducer } from 'react';
import { NavigationEntity } from '../NavigationEntity';
import { AASky, AScene, AAssets } from '../../entities';
import { SceneConfig, Navigator } from './types';
import { sleep } from '@oop/data';
import {} from '../';
import 'aframe';
import { insert } from 'ramda';

const normalPathToLowresPath = (s: string) =>
  insert(1, 'lowres/render0', s.split('render0')).join('');

/**
 * To be more specific, if I wrote "string" it could mean any string, with this
 * I try to express my intention, that you shouldn't write any string, but the
 * path of a sky
 */
type SkyPath = string;

export interface TotemProps<T> {
  content: T;
  position: string;
  rotation: string;
}

interface VirtualExperienceProps<T> {
  skies: SkyPath[];
  initialSky: SkyPath;
  assets: ReactNode[];
  sceneConfigs: Record<SkyPath, SceneConfig<T>>;
  hotspotPath: string;
  arrowPath: string;
  totemComponent: React.VFC<TotemProps<T>>;
}

/**
 * To simplify things, the path of a given sky will be the id that we will
 * need to refer to when using it in a "ASky" or a navigator "to"
 */

export const VirtualExperience = <T,>({
  skies,
  initialSky,
  assets,
  sceneConfigs,
  arrowPath,
  hotspotPath,
  totemComponent,
}: VirtualExperienceProps<T>) => {
  const [state, dispatch] = useReducer(reducer, {
    currentSky: initialSky,
    loadedSkies: [],
    isTransitioning: false,
    downloadedSkies: [],
  } as VirtualExperienceState);

  const goto = async (skyId: string) => {
    dispatch({ type: 'SET_ISTRANSITIONING', payload: true });
    await sleep(300);
    dispatch({ type: 'SET_ISTRANSITIONING', payload: false });
    dispatch({ type: 'CHANGE_SKY', payload: { skyId } });
  };

  const skyDownloaded = (skyId: string) => {
    console.log('Downloaded', skyId);
    if (skyId.includes('low')) return;
    dispatch({ type: 'FINISHED_DOWNLOADING', payload: { skyId } });
  };

  return (
    <AScene>
      <AASky
        isTransitioning={state.isTransitioning}
        src={`#${
          state.downloadedSkies.includes(state.currentSky)
            ? state.currentSky
            : normalPathToLowresPath(state.currentSky)
        }`}
      />
      <AAssets>
        {/*  */}
        {skies.map((sky, i) => (
          <img
            onLoad={() => skyDownloaded(sky)}
            alt="Sky"
            key={sky}
            id={sky}
            src={sky}
          />
        ))}
        <img alt="arrow" id="arrow" src={arrowPath} />
        <img alt="hotspot" id="hotspot" src={hotspotPath} />
        {/* Add extra assets */}
        {assets}
        {/* Render every navigator that corresponds to this Sky */}
      </AAssets>
      {sceneConfigs[state.currentSky].navigators.map(
        ({ position, rotation, to, type }) => (
          <NavigationEntity
            key={to}
            id={to}
            onClick={() => goto(to)}
            position={position}
            rotation={rotation}
            to={to}
            type={type}
          />
        )
      )}
      {sceneConfigs[
        state.currentSky
      ].totems.map(({ content, position, rotation }) =>
        totemComponent({ content, position, rotation })
      )}
    </AScene>
  );
};

type ACTION =
  | { type: 'CHANGE_SKY'; payload: { skyId: string } }
  | { type: 'SET_ISTRANSITIONING'; payload: boolean }
  | { type: 'FINISHED_DOWNLOADING'; payload: { skyId: string } };

interface VirtualExperienceState {
  loadedSkies: string[];
  downloadedSkies: string[];
  currentSky: string;
  isTransitioning: boolean;
}

const reducer: Reducer<VirtualExperienceState, ACTION> = (
  state,
  action: ACTION
) => {
  switch (action.type) {
    case 'CHANGE_SKY':
      return { ...state, currentSky: action.payload.skyId };
    case 'SET_ISTRANSITIONING':
      return { ...state, isTransitioning: action.payload };
    case 'FINISHED_DOWNLOADING':
      return {
        ...state,
        downloadedSkies: [...state.downloadedSkies, action.payload.skyId],
      };
  }
};

const LazyLoadRenders = (
  initialSky: string,
  currentSkies: string[],
  sceneConfig: Record<string, SceneConfig<unknown>>,
  depth: number
) => {
  const actualRenders = [...currentSkies];
  const initialNavigators = sceneConfig[initialSky].navigators;
  const pushRFNIfNeeded = (n: Navigator) => {
    if (!n) return;
    if (!actualRenders.includes(n.to)) {
      actualRenders.push(n.to);
    }
  };
  const pushNavigatorsIfPossible = (na: Navigator[], currDepth: number) => {
    if (currDepth === depth) return;
    na.forEach(pushRFNIfNeeded);
    const navigators = na.map((naa) => {
      if (!sceneConfig[naa.to]) return []; // If this config isn't implemented yet return
      return sceneConfig[naa.to].navigators;
    });
    navigators.forEach((ns) => pushNavigatorsIfPossible(ns, currDepth + 1));
  };
  pushNavigatorsIfPossible(initialNavigators, 0);
  return actualRenders;
};
