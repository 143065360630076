import React, { useContext } from 'react';
import { sleep } from '@oop/data';
import '../styles/main.css';
import styled from '@emotion/styled';
import { InformativeModal, ModalContext } from '@oop/ui';
import { CreateProposal } from '@plan-virtual/components/CreateProposal/CreateProposal';
import { createProposal } from '@plan-virtual/services/proposals/proposals.service';
import { Paper } from '@material-ui/core';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QrPage } from '@plan-virtual/app/QrPage';
import { HomePage } from '@plan-virtual/app/HomePage';
import { PrivacyPolicyPage } from '@plan-virtual/app/PrivacyPolicyPage';
import { FormPage } from '@plan-virtual/app/FormPage';

export function App() {
  return <FormPage />;
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/encuesta-satisfaccion">
          <QrPage />
        </Route>
        <Route exact path="/aviso-de-privacidad">
          <PrivacyPolicyPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );

  return (
    <div>
      <div tw="bg-blue-500">
        <div tw="">
          <h1>Plan de trabajo</h1>
          <p>Rectorado 2024 - 2027</p>
          <p>Dr. med. Santos Guzmán López</p>
        </div>
        <div>
          <img
            src="/assets/img.png"
            style={{
              maskImage:
                'linear-gradient(to bottom, black 80%, transparent 100% )',
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default App;
