import { compose, curry, last, reduce, split } from 'ramda';
import { Theme, ThemeProvider } from '@material-ui/core';

export const getColor = (c: keyof Theme['palette']) => ({
  theme,
}: {
  theme: Theme;
}) => theme.palette[c];

export const getMq = (mq: keyof Theme['breakpoints']) => ({
  theme,
}: {
  theme: Theme;
}) => theme.breakpoints[mq];

export const areEqual = curry(
  (a: string, b: string, c: string) => a === b && b === c
);

export const flatAccumulatorForCharacter = curry(
  (l: string, pv: string, cv: string) =>
    areEqual(last(pv), cv, l) ? `${pv}` : `${pv}${cv}`
);

export const flattenSlashbar = compose<string, string[], string>(
  reduce(flatAccumulatorForCharacter('/'), ''),
  split('')
);

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
