import React, { useEffect, useRef, useState } from 'react';
import { extractYoutubeId, videoLinkToEmbedLink } from '@oop/data';
import styled from '@emotion/styled';
import YouTube from 'react-youtube';

interface SingleVideoModal {
  rawLink: string;
  modalIsOn: boolean;
  onVideoPause: () => void;
  onVideoPlay: () => void;
}

export const SingleVideoModal: React.FC<SingleVideoModal> = ({
  onVideoPause,
  onVideoPlay,
  rawLink,
  modalIsOn,
}) => {
  const src = videoLinkToEmbedLink(rawLink);
  useEffect(() => {
    return onVideoPause;
  }, [modalIsOn]);
  return (
    <RespectRatioDiv>
      <YouTube
        onPause={onVideoPause}
        onPlay={onVideoPlay}
        className="video"
        videoId={extractYoutubeId(rawLink)}
      />
      {/* <ContainedIFrame
        ref={iframe}
        id="ytplayer"
        onClick={playedVideo}
        title="video"
        src={src}
      ></ContainedIFrame> */}
    </RespectRatioDiv>
  );
};

// 16:9
const RespectRatioDiv = styled.div`
  position: relative;
  padding-top: 56.25%;

  .video {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const ContainedIFrame = styled.iframe`
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;
