import React from 'react';
import { Container, Title, Paragraph } from '@oop/ui';
import { ReactNode } from 'react';
import { css, useTheme } from '@emotion/react';
import { Option } from 'fp-ts/lib/Option';
import styled from '@emotion/styled';
import { getColor, getMq } from 'libs/ui/src/utilities/utilities';

interface Section {
  title: string;
  iconsWText: {
    link: string | null;
    displayText: string;
    icon: ReactNode;
  }[];
}

export interface FooterAProps {
  sections: Section[];
}

export const FooterA = ({ sections }: FooterAProps) => {
  const { breakpoints } = useTheme();
  return (
    <Container
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        ${breakpoints.up('lg')} {
          grid-template-columns: 2fr 1fr 1fr;
          grid-template-rows: 1fr;
        }
        margin: 1em 0em;
      `}
    >
      {sections.map((section) => (
        <div
          css={css`
            display: grid;
            grid-gap: 0.5em;
          `}
        >
          <Title
            css={css`
              margin: 1em 0 0 0;
            `}
          >
            {section.title}
          </Title>
          {section.iconsWText.map((iwt) => (
            <SectionDiv>
              <div className="icon">{iwt.icon}</div>
              {iwt.link ? (
                <a target="_blank" href={iwt.link}>
                  {iwt.displayText}
                </a>
              ) : (
                <Paragraph
                  css={css`
                    display: inline;
                    margin: 0 1em 0 0;
                  `}
                >
                  {iwt.displayText}
                </Paragraph>
              )}
            </SectionDiv>
          ))}
        </div>
      ))}
      <br />
    </Container>
  );
};

const SectionDiv = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin: 0 0.7em 0 0;
    color: ${({ theme }) => theme.palette.primary.main};
    display: inline;
  }
`;
