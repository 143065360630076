import { split, last, compose } from 'ramda';

export const extractYoutubeId = (link: string) => {
  if (link.includes('watch?v=')) {
    const watchSplit = split('watch?v=');
    const ampersandSplit = split('&');
    const afterWatch = last(watchSplit(link));
    return ampersandSplit(afterWatch as string)[0];
  }

  if (link.includes('youtu.be')) {
    const id = link.split('.be/')[1];
    return id;
  }

  return '';
};

export const videoIDToEmbedLink = (id: string) =>
  `https://www.youtube.com/embed/${id}?enablejsapi=1&version=3&playerapiid=ytplayer`;

export const videoLinkToEmbedLink = compose(
  videoIDToEmbedLink,
  extractYoutubeId
);
