// import 'aframe';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import { Theme, ThemeProvider } from '@emotion/react';
import './styles/tailwind.css';
import './styles/global.scss';
import { store } from '@plan-virtual/store/store';
import { WithThemeProvider } from './theme/WithThemeProvider';
import { ModalProvider } from '@oop/ui';
import { Provider } from 'react-redux';

ReactDOM.render(
  <Provider store={store}>
    <WithThemeProvider>
      <ModalProvider>
        <App />
      </ModalProvider>
    </WithThemeProvider>
  </Provider>,
  document.getElementById('root')
);
