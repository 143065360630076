import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core';
import React from 'react';
import { Colors } from './Colors';

let muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.main,
      light: Colors.lightMain,
    },
    secondary: {
      main: Colors.secondary,
      light: Colors.lightSecondary,
    },
  },
});

muiTheme = responsiveFontSizes(muiTheme);
export { muiTheme };

export const WithThemeProvider: React.FC = ({ children }) => {
  return <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>;
};
