import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-weight: normal;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 1em;
  }
`;
