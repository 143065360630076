import firebase from 'firebase/app';
import {
  firebaseConfig,
  proposalsCollectionName,
} from '@oop/plan-virtual-data';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();
const analytics = app.analytics();

const functions = app.functions();
export const addVisita = functions.httpsCallable('addVisita');

export const proposalsCollection = db.collection(proposalsCollectionName);

export { db, analytics };
