import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

type PrivateRouteProps = {
  path: string;
  exact?: boolean;
  shouldAccess: boolean;
  redirectTo: string;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  exact,
  shouldAccess,
  redirectTo,
  children,
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        shouldAccess ? children : <Redirect to={redirectTo} />
      }
    />
  );
};
