import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Column,
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  Row,
} from 'react-table';
import { any, anyPass, has, isEmpty } from 'ramda';
import styled from '@emotion/styled';
import { getColor, Paragraph } from '@oop/ui';
import { css, Interpolation, Theme, useTheme } from '@emotion/react';
import {
  ArrowBack,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
} from '@material-ui/icons';
import { table } from 'node:console';

interface GlobalFilterProps<T extends object> {
  preGlobalFilteredRows: Row<T>[];
  globalFilter: any;
  setGlobalFilter: (v: any) => void;
}
const GlobalFilter = <T extends object>({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: GlobalFilterProps<T>) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = (v) => {
    setGlobalFilter(v || undefined);
  };

  return (
    <span
      css={css`
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0.5em 0;
      `}
    >
      <TextField
        color="primary"
        value={value || ''}
        variant="outlined"
        size="small"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Buscar"
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  );
};

function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  // @ts-ignore
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

export interface CustomTdReqs<T> {
  withInfo: (t: T) => ReactNode;
}
interface Header {
  name: string;
  minWidth: string;
}

export interface TableProps<T, K extends Record<string, CustomTdReqs<T>>> {
  data: T[];
  tableHeaders: Partial<Record<keyof T, Header>> & Record<keyof K, Header>; // Accesor and col name
  customTd?: Record<string, CustomTdReqs<T>>;
  extraActions?: K;
  status: 'notsent' | 'pending' | 'success' | 'error';
  minWidth: string;
}

export const Table = <T extends object, K>({
  data,
  tableHeaders,
  extraActions,
  customTd,
  status,
  minWidth,
}: TableProps<T, K & Record<string, CustomTdReqs<T>>>) => {
  const { palette } = useTheme();

  const columns = useMemo(
    () =>
      [
        ...Object.keys(tableHeaders).map((accessor) => ({
          Header: tableHeaders[accessor].name,
          accessor,
        })),
      ] as Column<T>[],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { globalFilter, pageIndex, pageSize },
  } = useTable<T>({ columns, data }, useGlobalFilter, useSortBy, usePagination);

  return (
    <div
      css={css`
        overflow: auto;
      `}
    >
      {isEmpty(data) ? (
        <h1 className="text-center mt-8 text-gray-400">
          {status === 'notsent' && 'Intenta buscar'}
          {status === 'success' && 'Sin resultados'}
          {status === 'pending' && <CircularProgress />}
        </h1>
      ) : status === 'success' ? (
        <>
          <GlobalFilter
            globalFilter={globalFilter}
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
          />
          <StyledTable
            css={css`
              thead tr {
                background-color: ${palette.primary.main};
              }
              min-width: ${minWidth};
            `}
            {...getTableProps()}
          >
            <thead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => (
                        // Apply the header cell props
                        <th {...column.getSortByToggleProps()}>
                          {
                            // Render the header
                            column.render('Header')
                          }
                          <IconButton
                            css={css`
                              margin: 0 0 0 0.5em;
                              * {
                                color: white;
                              }
                            `}
                            size="small"
                          >
                            {column.isSortedDesc ? (
                              <ArrowDropDown />
                            ) : (
                              <ArrowDropUp />
                            )}
                          </IconButton>
                        </th>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>
            {/* Table body start */}
            <tbody {...getTableBodyProps()}>
              {isEmpty(page) ? (
                <tr>
                  <td colSpan={3}>
                    <Paragraph
                      css={css`
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        color: #cccccc;
                        height: 5em;
                      `}
                    >
                      Sin resultados
                    </Paragraph>
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr
                      css={css`
                        &:nth-of-type(2n) {
                          background: ${palette.primary.main}22;
                        }
                      `}
                      {...row.getRowProps()}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          const id = cell.column.id as string;
                          const originalT = row.original;

                          const hasThisIdentifier = has(id);

                          const isNotOriginal = any(hasThisIdentifier, [
                            extraActions,
                            customTd,
                          ]);

                          const center = `
                            display: flex;
                            justify-content: center;
                          `;

                          let minWidthOfThisTd = '0px';

                          if (has(id, tableHeaders)) {
                            minWidthOfThisTd = tableHeaders[id].minWidth;
                          }

                          return (
                            <td
                              css={css`
                                width: ${minWidthOfThisTd};
                                max-width: ${minWidthOfThisTd};
                                ${isNotOriginal ? center : ''}
                                padding: 1em 1em;
                              `}
                              {...cell.getCellProps()}
                            >
                              {
                                // Render the cell contents
                                has(id, extraActions) ? (
                                  extraActions[id].withInfo(originalT)
                                ) : has(id, customTd) ? (
                                  customTd[id].withInfo(originalT)
                                ) : (
                                  <Paragraph>{cell.render('Cell')}</Paragraph>
                                )
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              )}
            </tbody>
          </StyledTable>
          {/* Pagination */}
          <div
            css={css`
              margin: 1em 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              min-width: ${minWidth};
            `}
          >
            <Paragraph
              css={css`
                margin-right: 0.5em;
              `}
            >
              Página{' '}
              <span>
                {pageIndex + 1} <span>de</span> {pageOptions.length}
              </span>
            </Paragraph>
            <span
              css={css`
                margin: 0 2em;
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              <Paragraph>Ir a la página: &nbsp;</Paragraph>
              <TextField
                className="ml-4"
                type="number"
                defaultValue={pageIndex + 1}
                size="small"
                variant="outlined"
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '60px ' }}
              />
            </span>{' '}
            <TextField
              className="mr-8"
              value={pageSize}
              select
              size="small"
              variant="outlined"
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[1, 10, 20, 30, 40, 50].map((pageSize) => (
                <MenuItem key={pageSize} value={pageSize}>
                  Mostrar {pageSize}
                </MenuItem>
              ))}
            </TextField>
            <IconButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <ArrowBack />
            </IconButton>
            <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
              <ArrowForward />
            </IconButton>
          </div>
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}; // const onChange = useAsyncDebounce((value) => {
//   setGlobalFilter(value || undefined);
// }, 200);

export const StyledTable = styled.table`
  width: 100%;
  border-radius: 5px;
  border-collapse: collapse;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

  thead th {
    // background-color: ${({ theme: { palette } }) => palette.primary.main};
    color: #efefef;
    padding: 1em 0em;
  }
`;

export default Table;
