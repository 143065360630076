import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { User } from '@firebase/auth-types';
import { LoadingScreenA } from '@oop/ui';
import firebase from 'firebase/app';

export const AuthContext = createContext<User | null>(null);

type AuthProviderProps = {
  children: ReactNode | ReactNode[];
  auth: firebase.auth.Auth;
  onUser: (user: firebase.User) => any;
};

export function AuthProvider({ children, auth }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);
  if (pending) {
    return <LoadingScreenA />;
  }
  return (
    <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
  );
}
