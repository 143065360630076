import React from 'react';
import tw from 'twin.macro';
export function PrivacyPolicyPage() {
  return (
    <div tw="bg-gray-100 min-h-screen min-h-screen p-8">
      <div tw="bg-white p-4 flex flex-col space-y-4 shadow-md rounded-sm mx-auto max-w-2xl">
        <h1 tw="text-lg font-bold">Aviso de privacidad</h1>
        <p>
          Los datos personales que voluntariamente proporciona a este Sitio de
          forma electrónica o personal, serán recabados, protegidos,
          incorporados y tratados en el Sistema de Datos Personales de esta
          Universidad Autónoma de Nuevo León, ubicada en Av. Universidad s/n,
          Ciudad Universitaria, San Nicolás de los Garza, Nuevo León, ello, con
          fundamento en el párrafo segundo del artículo 16 de la Constitución
          Política de los Estados Unidos Mexicanos en correlación con la Ley
          General de Protección de Datos Personales en Posesión de Sujetos
          Obligados, Ley de Transparencia y Acceso a la Información Pública del
          Estado de Nuevo León, Ley de Transparencia y Acceso a la Información
          del Estado de Nuevo León y Reglamento de Transparencia y Acceso a la
          Información Pública de esta UANL.
        </p>
        <p>
          Debiendo tomar en consideración que por datos personales debe
          entenderse cualquier información concerniente a una persona física
          identificada o identificable, como lo son: domicilio particular,
          número de teléfono particular, cuenta personal de correo electrónico,
          origen étnico, estado de salud, huella digital, etc.
        </p>
        <p>
          En la inteligencia que el responsable del sistema de sus datos
          personales es la autoridad que los recaba, los mismos serán utilizados
          única y exclusivamente para los fines propios de cada una de las
          Dependencias Universitarias que conforman ésta Institución, tales como
          Escuelas Preparatorias, Facultades y Departamentos Administrativos.
          Asimismo, tales datos personales podrán ser proporcionados únicamente
          a la Autoridad Universitaria, que al efecto acredite contar con
          facultad para ello.
        </p>
        <p>
          En caso de negativa a suministrar los datos personales requeridos por
          la autoridad y/o funcionario, interrumpirá indefinidamente el trámite
          y/o procedimiento a realizar en esta Universidad.
        </p>
        <p>
          La autenticidad y actualización de los datos proporcionados es
          responsabilidad tanto de usted como de la autoridad que los recaba.
        </p>
        <p>
          Ahora bien usted podrá ejercer sus derechos de Acceso, Rectificación,
          Cancelación u Oposición de sus Datos Personales, de manera presencial
          ante la Unidad de Transparencia, específicamente en la Coordinación de
          Protección de Datos Personales de la UANL, o bien obtener el formato
          de solicitud de derechos ARCO en la página,{' '}
          <a href="https://transparencia.uanl.mx" target="_blank">
            transparencia.uanl.mx
          </a>
        </p>
        <p>
          Finalmente para cualquier cambio respecto a este Aviso de Privacidad,
          esta UANL se reserva el derecho a efectuar en cualquier momento
          modificaciones o actualizaciones al presente aviso y se compromete a
          informar a Usted, de manera oportuna, a través de los medios adecuados
          como lo es esta página web{' '}
          <a href="https://uanl.mx" target="_blank">
            www.uanl.mx
          </a>{' '}
          o mediante correo electrónico que usted proporcione a esta
          Institución.
        </p>
        <p>Responsable: Juan Luis Hernández Carrizales</p>
      </div>
    </div>
  );
}
