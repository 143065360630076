import React from 'react';
import tw from 'twin.macro';
import { useMediaQuery } from '@material-ui/core';
export function HomePageHero() {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  return (
    <div>
      <div tw="relative">
        {!isDesktop && <img tw="md:hidden" src="/assets/hero_sm.jpeg" />}
        {isDesktop && <img tw="hidden md:block" src="/assets/hero_lg.png" />}
        <div
          style={{
            transform: 'translateX(-50%)',
          }}
          tw="hidden md:flex absolute w-2/3 lg:w-3/4 bottom-8 lg:bottom-12 space-x-4 xl:bottom-16  items-center justify-center left-1/2"
        >
          <HomePageButtons />
        </div>
      </div>
      <div
        tw="md:hidden -mt-2 px-4 py-4 space-y-4 relative flex flex-col"
        style={{
          background: '#053c7e',
        }}
      >
        <HomePageButtons />
      </div>
    </div>
  );
}

function HomePageButtons() {
  return (
    <>
      <a
        href="https://forms.gle/irheXPbHEzzhtCrh7"
        target="_blank"
        style={{ background: '#b68c1d' }}
        tw="text-lg h-14 flex items-center justify-center space-x-4 cursor-pointer py-3 px-4 w-full leading-4 text-white text-center rounded-md shadow-sm"
      >
        <svg
          tw="h-6 w-6 my-auto"
          fill="#ffffff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
        >
          <path d="M128 0C110.3 0 96 14.3 96 32V224h96V192c0-35.3 28.7-64 64-64H480V32c0-17.7-14.3-32-32-32H128zM256 160c-17.7 0-32 14.3-32 32v32h96c35.3 0 64 28.7 64 64V416H576c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32H256zm240 64h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H496c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zM64 256c-17.7 0-32 14.3-32 32v13L187.1 415.9c1.4 1 3.1 1.6 4.9 1.6s3.5-.6 4.9-1.6L352 301V288c0-17.7-14.3-32-32-32H64zm288 84.8L216 441.6c-6.9 5.1-15.3 7.9-24 7.9s-17-2.8-24-7.9L32 340.8V480c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V340.8z" />
        </svg>
        <span>Enviar propuesta / pregunta</span>
      </a>
      <a
        style={{ background: '#b68c1d' }}
        href="/assets/plan_trabajo.pdf"
        download
        tw="text-lg flex space-x-4 text-white h-14 cursor-pointer justify-center items-center lg:mt-0 py-3 px-4 w-full leading-4 text-center rounded-md shadow-sm"
      >
        <svg
          fill="#ffffff"
          tw="h-6 w-6 my-auto"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
        >
          <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z" />
        </svg>
        <span>Descargar Plan de Trabajo</span>
      </a>
      <a
        style={{ background: '#b68c1d' }}
        href="https://youtu.be/tenEJH5tdF0"
        target="_blank"
        tw="text-lg flex space-x-4 h-14 text-white cursor-pointer justify-center items-center lg:mt-0 py-3 px-4 w-full leading-4 text-center rounded-md shadow-sm"
      >
        <svg
          tw="h-6 w-6 my-auto"
          fill="#ffffff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
        </svg>
        <span>Ver Video Plan de Trabajo</span>
      </a>
    </>
  );
}

export function OldHomePageHero() {
  return (
    <div tw="py-20 md:py-8">
      <div tw="container px-4 mx-auto">
        <div
          style={{
            backgroundImage: 'url("/assets/img_1.png")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
          tw="flex flex-wrap xl:items-center -mx-4"
        >
          <div tw="w-full flex flex-col  justify-center md:w-1/2 px-4 mb-16 md:mb-0">
            <div>
              <span
                style={{ background: '#e6a92b' }}
                tw="inline-block py-px px-2 mb-4 text-xs leading-5 bg-blue-500 text-white uppercase rounded-md"
              >
                Rectorado 2024 - 2027
              </span>
            </div>
            <h1 tw="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tight">
              Plan de Trabajo
            </h1>
            <p tw="mb-4 text-lg md:text-xl text-gray-700 font-medium">
              Dr. med. Santos Guzman Lopez
            </p>
            <p tw="mb-6 text-sm text-gray-400 ">
              <p>La excelencia como principio</p>
              <p>la educacion como instrumento</p>
            </p>
            <div tw="flex flex-col lg:flex-row">
              <a
                style={{ background: '#04457f' }}
                tw="cursor-pointer inline-block lg:mr-4 py-2 px-4 w-full leading-4 text-white text-center rounded-md shadow-sm"
              >
                Enviar propuesta
              </a>
              <a
                style={{ border: '1px solid #04457f', color: '#04457f' }}
                href="/assets/plan_trabajo.pdf"
                download
                tw="cursor-pointer justify-center items-center inline-block mt-4 lg:mt-0 py-2 px-4 w-full leading-4 text-center rounded-md shadow-sm"
              >
                <div tw="flex justify-center space-x-2">
                  <svg
                    fill="#04457f"
                    tw="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z" />
                  </svg>
                  <span>Plan de trabajo</span>
                </div>
              </a>
            </div>
          </div>
          <div tw="w-full md:w-1/2 px-4">
            <div tw="relative mx-auto md:mr-0 max-w-max">
              <img
                style={{
                  maskImage:
                    'linear-gradient(to bottom, black 80%, transparent 100% )',
                }}
                tw="relative rounded-sm"
                src="/assets/img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
