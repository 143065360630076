import styled from '@emotion/styled';

export const Container = styled.div`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0 1em;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 10em;
  }
`;
