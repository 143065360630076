import { useTheme } from '@emotion/react';
import { AEntity, AText } from '@oop/ui';
import React from 'react';
import { IconConfig } from './Totem2Rows';

interface Totem1RowProps {
  position: string;
  rotation: string;
  icon: IconConfig;
}

export const Totem1Row: React.FC<Totem1RowProps> = ({
  icon,
  position,
  rotation,
}) => {
  const { palette } = useTheme();
  return (
    <AEntity
      rotation={rotation}
      id="main_totem"
      color="white"
      geometry="box"
      scale="0.368 0.6 0.1"
      position={position}
    >
      <AEntity
        id="main_totem_decoration"
        color={palette.primary.main}
        geometry="box"
        scale="0.1 1 0.1"
        position="-0.52 0 0.5"
      />
      <AEntity
        handleClick={icon.handleClick}
        key={icon.iconID + String(Math.random())}
        src={icon.iconID}
        position="0.03 -0.1 1"
        scale="0.8 0.5 1"
        isIcon
      />
      {/* <AText
        align="center"
        color="#333333"
        rotation="0 0 0"
        scale="0.9 0.5 1"
        position="0 0.4 1.1"
        wrapCount={icon.wrapCount || 40}
        // text={icon.title}
        text="Mensaje"
      /> */}
      <AText
        align="center"
        color="#333333"
        rotation="0 0 0"
        scale="0.6 0.5 1"
        position="0 0.25 1.1"
        // wrapCount={icon.wrapCount || 40}
        text={icon.title}
        // text="final"
      />
    </AEntity>
  );
};
