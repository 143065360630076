import React from 'react';

import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { HomePageNavbar } from '@plan-virtual/app/HomePageNavbar';
import { HomePageCollage } from '@plan-virtual/app/HomePageCollage';
import { HomePageFeatures } from '@plan-virtual/app/HomePageFeatures';
import { HomePageHero } from '@plan-virtual/app/HomePageHero';

export function HomePage() {
  const history = useHistory();
  return (
    <div>
      <HomePageHero />
      <HomePageFeatures />
      <HomePageCollage />
      <div
        tw="h-40 space-y-8 justify-center flex-col flex items-center w-full px-20"
        style={{ background: '#04457f' }}
      >
        <div tw="flex items-center justify-center space-x-16">
          {[
            '/assets/Logo-UANL-BLANCO.png',
            '/assets/logo_90_uanl-03.png',
            '/assets/Logo-Excelencia-Blanco.png',
          ].map((img) => (
            <img loading="lazy" style={{ height: '4em' }} src={img} />
          ))}
        </div>
        <Link tw="text-white" to="/aviso-de-privacidad">
          Aviso de privacidad
        </Link>
      </div>
    </div>
  );
}
