import { TextField } from '@material-ui/core';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import React, { Dispatch, SetStateAction } from 'react';

type DateFieldProps = {
  label: string;
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
};

export const DateField = ({ label, date, setDate }: DateFieldProps) => {
  return (
    <div>
      <MobileDatePicker
        disableOpenPicker
        label={label}
        inputFormat="dd/MM/yyyy"
        value={date}
        cancelText="Cancelar"
        onChange={(d) => d && setDate(d)}
        renderInput={(params) => (
          <TextField margin="normal" {...params} variant="standard" />
        )}
        OpenPickerButtonProps={{
          'aria-label': 'cambiar fecha',
        }}
      />
    </div>
  );
};
