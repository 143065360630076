export const firebaseConfig = {
  apiKey: 'AIzaSyDhducT2L5mVScO3jqe0FOw8eKw4sksdO4',
  authDomain: 'plantrabajo-b28fa.firebaseapp.com',
  projectId: 'plantrabajo-b28fa',
  storageBucket: 'plantrabajo-b28fa.appspot.com',
  messagingSenderId: '508811567252',
  appId: '1:508811567252:web:c1751e704dceca3fb61481',
  measurementId: 'G-NN2HR1QXY6',
};

export const proposalsCollectionName = 'propuestas';
