import React from 'react';
import { TextField } from '@material-ui/core';
import { Context, ReactNode, useContext, useEffect } from 'react';
import { MobileDatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useState } from 'react';
import { es } from 'date-fns/locale';
import { subDays } from 'date-fns';
import { DateField } from './DateField';

type SelectDateIntervalForm = {
  from: string;
  to: string;
};

interface SelectDateIntervalProps {
  openModalWith: (n: ReactNode) => void;
  onIncorrectSelection: ReactNode;
  submitOnMount?: boolean;
  onDatesSelected: (from: Date, to: Date) => void;
}

export const SelectDateInterval: React.FC<SelectDateIntervalProps> = ({
  openModalWith,
  onIncorrectSelection,
  onDatesSelected,
  submitOnMount = false,
}) => {
  const [from, setFrom] = useState(subDays(new Date(), 1));
  const [to, setTo] = useState(new Date());

  useEffect(() => {
    if (submitOnMount) {
      handleFormSubmit();
    }
  }, []);

  const handleFormSubmit = () => {
    if (from.getTime() - to.getTime() > 0)
      return openModalWith(onIncorrectSelection);
  };

  return (
    <div className="flex flex-col items-end">
      <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
        <div className="flex flex-row">
          <DateField date={from} setDate={setFrom} label="Desde" />
          <DateField date={to} setDate={setTo} label="Hasta" />
        </div>
      </LocalizationProvider>
      <button
        onClick={() => onDatesSelected}
        className="px-4 py-2 bg-mintStr text-uanlBlue mt-4 rounded-md"
      >
        Buscar
      </button>
    </div>
  );
};
